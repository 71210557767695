import {
  ActionIcon,
    Badge,
    Box,
    Button,
    Divider,
    Group,
    Menu,
    Modal,
    Paper,
    Text,
    Tooltip
} from "@mantine/core";
import React, { useState } from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaEdit, FaEllipsisV, FaPlus, FaTags, FaTrash, FaWrench } from "react-icons/fa";
import { timezones } from "../../utility/util";
import { useApp } from "../../contexts/app.context";
import { Fa0, FaArrowDown19, FaCheckToSlot, FaFont } from "react-icons/fa6";

export default function OthersVariables({ data, setData }: any){
    const { str } = useLanguage();
    const { confirmDialog } = useApp();

    const [updatingVariable, setUpdatingVariable] = useState<any>(null);

    return <>
      <Group mb="md">
        <FaTags />
        <Text size="lg" fw="bold" style={{flex: 1}}>{str("AGENTS.AFTER_CALL_VARIABLES")}</Text>
        <Button
          onClick={() => { setUpdatingVariable({}) }}
          leftSection={<FaPlus />}
          size="sm"
          variant="outline"
          color="white"
        >Add</Button>
      </Group>

      {(data?.data?.extract_variables ?? []).length === 0 && <Box mt="80px" mb="80px">
        <Text mb="xs" fw="bold" c="white" size="lg" ta="center">{str("AGENTS.NO_VARIABLE_AFTER_CALL_TITLE")}</Text>
        <Text c="white" size="sm" ta="center">{str("AGENTS.NO_VARIABLE_AFTER_CALL_SUBTITLE")}</Text>
      </Box>}
      
      {(data?.data?.extract_variables ?? []).map((variable, ai) => <Paper p="md" mt="md" style={{backgroundColor: '#242442'}}>
        <Group mb="xs">
          <Tooltip label={{
            "string": "Text",
            "number": "Number",
            "boolean": "Yes/No",
          }[variable?.type ?? "string"] ?? "Text"}>
            <ActionIcon
              variant="light"
              onClick={() => {
                setData(na => ({ ...na, data: { ...na?.data, extract_variables: na.data?.extract_variables.map((v2, aj) => ai === aj ? { ...v2, type: v2.type === "number" ? "boolean" : v2.type === "boolean" ? "string" : "number" } : v2) } }))
              }}
            >{variable?.type === "number" ? <FaArrowDown19 /> : variable?.type === "boolean" ? <FaCheckToSlot /> : <FaFont />}</ActionIcon>
          </Tooltip>
          <Text size="lg">{variable?.key}</Text>
          <div style={{flex: 1}}></div>
          <Menu>
            <Menu.Target>
              <ActionIcon color="white" variant="transparent"><FaEllipsisV /></ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => setUpdatingVariable({ index: ai, variable })}
                leftSection={<FaEdit />}
              >{str("UPDATE")}</Menu.Item>
              <Menu.Item
                leftSection={<FaTrash />}
                onClick={() => {
                  confirmDialog({ text: str("AGENTS.CONFIRM_REMOVE_ACTION") }, ({ confirmed }) => {
                    if(confirmed){
                      setData(na => ({ ...na, data: { ...na?.data, extract_variables: na.data?.extract_variables.filter((v2, aj) => ai !== aj) } }))
                    }
                  })
                }}
              >{str("REMOVE")}</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Text size="sm">Description: {variable?.description}</Text>
      </Paper>)}

      <Modal
        opened={!!updatingVariable}
        onClose={() => setUpdatingVariable(null)}
        size="sm"
      >
        <InputField
          name="type"
          fieldType={InputFieldTypes.SELECT}
          clearable={false}
          searchable={false}
          value={updatingVariable?.variable?.type ?? "string"}
          onChange={({type}) => {
            setUpdatingVariable(ut => ({ ...ut, variable: {...ut?.variable, type }}))
          }}
          options={[
            { value: "string", label: "Text" },
            { value: "number", label: "Number" },
            { value: "boolean", label: "Yes/No" },
          ]}
          mb="md"
        />
        <InputField
          name="key"
          value={updatingVariable?.variable?.key}
          onChange={({ key }) => {
            setUpdatingVariable(ut => ({ ...ut, variable: {...ut?.variable, key: (key ?? "").toLowerCase().replace(/ /g, "_") }}))
          }}
          placeholder="Type the name of the variable"
          mb="md"
        />
        <InputField
          name="description"
          value={updatingVariable?.variable?.description}
          onChange={({description}) => {
            setUpdatingVariable(ut => ({ ...ut, variable: {...ut?.variable, description }}))
          }}
          fieldType={InputFieldTypes.TEXTAREA}
          placeholder="Describe what is this variable and how this should be extracted during the conversation"
          mb="md"
        />
        <Group justify="flex-end">
          <Button
            onClick={() => {
              setData(na => ({ ...na, data: { ...na?.data, extract_variables: (
                ![null, undefined].includes(updatingVariable?.index)
                ? (na.data?.extract_variables ?? []).map((v2, aj) => updatingVariable?.index === aj ? { ...v2, ...updatingVariable?.variable } : v2)
                : [...(na.data?.extract_variables ?? []), updatingVariable?.variable]
              ) } }));
              setUpdatingVariable(null);
            }}
          >{str("SAVE")}</Button>
        </Group>
      </Modal>
    </>
}
