import { api } from "./api"

export const GET_ALL_AGENTS = async () => {
    const {data} = await api.get("/agents")
    return data.agents || [];
}

export const GET_ALL_TEMPLATES = async () => {
    const {data} = await api.get("/agents/templates")
    return data.agents || [];
}

export const GET_AGENT_DETAILS = async (id) => {
    const {data} = await api.get(`/agents/${id}`)
    return data;
}

export const DELETE_AGENT = async (id) => {
    const {data} = await api.delete(`/agents/${id}`)
    return data;
}

export const SAVE_AGENT = async (params : any) => {
    const {data} = await api.post(`/agents`, params)
    return data;
}

export const GENERATE_AGENT = async (params : any) => {
    const {data} = await api.post(`/agents/generate/new`, params)
    return data;
}

export const START_CONVERSATION = async (agentId, others = {}) => {
    const {data} = await api.post(`/agents/${agentId}/start/conversation`, { ...others, version: 2 })
    return data;
}
