import {
  Button,
  LoadingOverlay,
  PasswordInput,
  Space,
  Title
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";

export default function Invite(){
  const { signIn } = useAuth();
  const {key} = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(key){
      api.post("/auth/validate/confirm", { uuid: key })
      .then(({data: {user}}) => {
        signIn(user, () => navigate("/", { replace: true }));
      })
      .catch(err => {
        navigate("/");
        notifications.show({title: 'Ops', message: err.message, color: 'red'})
      })
    }
  }, [key, navigate])

  return <LoadingOverlay visible={true} />
};
