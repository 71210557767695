import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { DELETE_PHONE, GET_ALL_PHONES, GET_AVAILABLE_PHONES, SAVE_PHONE, SAVE_WHATSAPP, UPDATE_PHONE, VERIFY_TELNYX_PHONE } from "../../services/phones";
import InputField, { InputFieldTypes } from "../input_field";
import { ActionIcon, Avatar, Badge, Box, Button, Grid, Group, Loader, Modal, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { FaCartPlus, FaPhone } from "react-icons/fa";
import { countriesList } from "../../utility/util";
import { useApp } from "../../contexts/app.context";
import { useLanguage } from "../../contexts/language.context";

export default function Phones(){
    const [data, setData] = useState<any[]>([]);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [loadingPhones, setLoadingPhones] = useState<boolean>(false);
    const [newPhone, setNewPhone] = useState<any>(null);
    const [verificationCode, setVerificationCode] = useState<any>({});
    const [availablePhones, setAvailablePhones] = useState<any[]>([]);

    const { confirmDialog } = useApp();
    const { str } = useLanguage();

    const handleChange = dt => setData(u => ({ ...u, ...dt }))
    
    const loadPhones = () => {
        GET_ALL_PHONES()
        .then((res) => {
            setData(res)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const loadAvailablePhones = () => {
        setAvailablePhones([]);
        setLoadingPhones(true);
        GET_AVAILABLE_PHONES({ countryCode: newPhone?.countryCode })
        .then((res) => {
            setLoadingPhones(false);
            setAvailablePhones(res)
        })
        .catch(err => {
            setLoadingPhones(false);
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        SAVE_PHONE({ ...newPhone })
        .then((res) => {
            setLoadingSave(false)
            loadPhones()
            setNewPhone(null);
            notifications.show({message: str("PHONES.CONNECTED"), color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadPhones() }, [])
    React.useEffect(() => { loadAvailablePhones() }, [newPhone?.countryCode])

    return <>
        <Paper p="md">
            <Group>
                <FaPhone />
                <Title style={{flex: 1}} order={5}>{str("PHONES.TITLE")}</Title>
                <Button
                    leftSection={<FaCartPlus />}
                    onClick={() => { setNewPhone({ type: "new", countryCode: "US" }) }}
                >{str("PHONES.BUY")}</Button>
            </Group>
        </Paper>

        <Grid mt="md">
            {data.map((phone : any) => (
                <Grid.Col span={{base: 12, md: 6}}>
                    {/* <UnstyledButton style={{width: '100%'}} onClick={() => { setSelectedKnowledgeCategory(phone)}}> */}
                        <Paper p="md" shadow="xs" style={{backgroundColor: '#242442'}}>
                            <Group mb="xs">
                                {phone.type !== "sip" && <ActionIcon
                                    size="lg"
                                    color={
                                        (
                                            ["success", "in-use"].includes(phone?.data?.twilio?.status) ||
                                            phone?.data?.telnyx?.status
                                        )
                                        ? "green" : "gray"}
                                    onClick={() => {
                                        if(!["success", "in-use"].includes(phone?.data?.twilio?.status)){
                                            if(phone.type === "host" && phone.origin !== "telnyx"){
                                                confirmDialog({ text: str("PHONES.RESEND_VERIFICATION") }, ({ confirmed }) => {
                                                    if(confirmed){
                                                        SAVE_PHONE(phone)
                                                        .then((res) => {
                                                            loadPhones()
                                                            notifications.show({message: str("PHONES.CONNECTED"), color: 'green'})
                                                        })
                                                        .catch(err => {
                                                            notifications.show({title: "Ops.", message: err.message, color: 'red'})
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                ><FaPhone /></ActionIcon>}
                                <Box style={{flex: 1}}>
                                    <Text ta="center" fw="bold" size="sm">{phone.number}</Text>
                                    {
                                        phone?.data?.twilio_verification?.validationCode && 
                                        !["success", "in-use"].includes(phone?.data?.twilio?.status) && 
                                        <Text ta="center" size="md" mt="md">{str("PHONES.VALIDATION_CODE")}: {phone?.data?.twilio_verification?.validationCode}</Text>}
                                </Box>
                            </Group>
                            {!phone?.data?.telnyx?.status && phone.type === "host" && phone.origin === "telnyx" && 
                                (
                                    phone?.data?.telnyx?.error
                                    ? <Group mb="md">
                                        <Text style={{flex: 1}} pr="xl"></Text>
                                        <Button
                                            size="md"
                                            onClick={() => {
                                                SAVE_PHONE(phone)
                                                .then((res) => {
                                                    loadPhones()
                                                })
                                                .catch(err => {
                                                    notifications.show({title: "Ops.", message: err.message, color: 'red'})
                                                })
                                            }}
                                        >Resend Code</Button>
                                    </Group>
                                    : <Group mb="md">
                                        <InputField
                                            name="code"
                                            style={{flex: 1, border: '1px solid #CACACA', borderRadius: 4}}
                                            placeholder="Type the verification code"
                                            value={verificationCode[phone._id]}
                                            onChange={({code}) => setVerificationCode(vc => ({ ...vc, [phone._id]: code}))}
                                        />
                                        <Button
                                            size="md"
                                            disabled={(verificationCode[phone._id] ?? "").length < 5}
                                            onClick={() => {
                                                VERIFY_TELNYX_PHONE(phone._id, verificationCode[phone._id])
                                                .then(() => {
                                                    loadPhones();
                                                    notifications.show({ message: "Phone verified", color: "green" });
                                                })
                                                .catch(err => {
                                                    loadPhones();
                                                    notifications.show({ message: err.message, color: "red" });
                                                })
                                            }}
                                        >Verify</Button>
                                    </Group>
                                )
                            }
                            <Group justify="flex-end">
                                <UnstyledButton>
                                    <Badge
                                        size="xs"
                                        variant="outline"
                                        color="gray"
                                        onClick={() => {
                                            confirmDialog({ text: str("PHONES.DELETE_CONFIRM") }, ({ confirmed }) => {
                                                if(confirmed){
                                                    DELETE_PHONE(phone._id)
                                                    .then(() => {
                                                        loadPhones();
                                                        notifications.show({ message: str("PHONES.DELETED"), color: "green" });
                                                    })
                                                    .catch(err => {
                                                        notifications.show({ message: err.message, color: "red" });
                                                    })
                                                }
                                            })
                                        }}
                                    >Remove</Badge>
                                </UnstyledButton>
                            </Group>
                        </Paper>
                    {/* </UnstyledButton> */}
                </Grid.Col>
            ))}
        </Grid>
        
        <Modal
            opened={!!newPhone}
            onClose={() => setNewPhone(null)}
        >
            <Text size="md" ta="center" mb="md">{
                {
                    new: str("PHONES.BUY_TITLE"),
                    host: str("PHONES.BUY_HOST_TITLE"),
                }[newPhone?.type]
            }</Text>

            <InputField
                mb="md"
                name="type"
                fieldType={InputFieldTypes.SELECT}
                clearable={false}
                searchable={false}
                value={newPhone?.type ?? "new"}
                onChange={({type}) => setNewPhone(np => ({ ...np, type }))}
                options={[
                    { label: str("PHONES.NEW_PHONE"), value: "new" },
                    { label: str("PHONES.HOST_PHONE"), value: "host" },
                    { label: str("PHONES.SIP_PHONE"), value: "sip" },
                ]}
            />

            {newPhone?.type === "host" && <Text ta="center" mt="md" mb="md">{str("PHONES.HOST_DESCRIPTION")}</Text>}

            {newPhone?.type === "new" && <InputField
                title={str("COUNTRY")}
                mb="md"
                name="countryCode"
                fieldType={InputFieldTypes.SELECT}
                value={newPhone?.countryCode}
                onChange={({countryCode}) => setNewPhone(np => ({ ...np, countryCode }))}
                nothingFound="No country code found"
                options={countriesList.filter(c => [
                    "US", "BR", "UK", "SG", "CA", "NL", "AU", "DE", "IT", "PL", "AR", "AT", "ZA"
                ].includes(c.value) )}
            />}
            
            {
                newPhone?.type === "sip"
                ? <>
                    <InputField
                        title={str("PHONE_NUMBER")}
                        mb="md"
                        name="number"
                        value={newPhone?.number}
                        onChange={({number}) => setNewPhone(np => ({ ...np, number }))}
                    />
                    <InputField
                        title={str("PHONES.HOST")}
                        mb="md"
                        name="host"
                        value={newPhone?.data?.host}
                        onChange={({host}) => setNewPhone(np => ({ ...np, data: { ...np?.data, host } }))}
                    />
                    <InputField
                        title={str("PHONES.PORT")}
                        mb="md"
                        name="port"
                        value={newPhone?.data?.port}
                        onChange={({port}) => setNewPhone(np => ({ ...np, data: { ...np?.data, port } }))}
                    />
                    <InputField
                        title={str("PHONES.EXTENSION")}
                        mb="md"
                        name="extension"
                        value={newPhone?.data?.extension}
                        onChange={({extension}) => setNewPhone(np => ({ ...np, data: { ...np?.data, extension } }))}
                    />
                    <InputField
                        title={str("PHONES.PASSWORD")}
                        mb="md"
                        name="password"
                        value={newPhone?.data?.password}
                        onChange={({password}) => setNewPhone(np => ({ ...np, data: { ...np?.data, password } }))}
                    />
                </>
                : <InputField
                    title={str("PHONE_NUMBER")}
                    mb="md"
                    name="number"
                    disabled={newPhone?.type !== "host" && !newPhone?.countryCode}
                    fieldType={newPhone?.type === "host" ? undefined : InputFieldTypes.SELECT}
                    value={newPhone?.number}
                    leftSection={loadingPhones && <Loader size="xs" />}
                    onChange={({number}) => setNewPhone(np => ({ ...np, number }))}
                    nothingFound="No phone number available"
                    options={availablePhones.map(ap => ({ value: ap.phoneNumber, label: ap.friendlyName }))}
                />
            }

            <Group justify="flex-end">
                <Button loading={loadingSave} onClick={handleSave}>{str("PHONES.ORDER")}</Button>
            </Group>
        </Modal>
    </>
}
