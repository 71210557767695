import { Box, Button, Grid, Group, Switch } from '@mantine/core';
import React, { useState } from 'react';
import BlogItemGeneration from '../components/blog_item_generation';
import InputField, { InputFieldTypes } from '../components/input_field';

export default function BlogGenerator(){
    const [items, setItems] = useState<any[]>([]);
    const [isDraft, setIsDraft] = useState<boolean>(false);
    const [insertingText, setInsertingText] = useState("");

    const submit = () => {
        let newItems = insertingText.split("\n").filter(t => t && t.length > 3).map(it => ({ title: it }));
        setItems(i => [...i, ...newItems]);
        setInsertingText("");
    }

    return <div>
        <Group align='flex-end'>
            <InputField
                name="it"
                style={{flex: 1}}
                value={insertingText}
                fieldType={InputFieldTypes.TEXTAREA}
                onChange={({ it }) => setInsertingText(it)}
            />
            <Box>
                <Switch mt="md" label="Is Draft" checked={isDraft} onChange={() => setIsDraft(id => !id)} />
                <Button mt="md" onClick={() => { submit() }} disabled={!insertingText}>Submit</Button>
            </Box>
        </Group>

        <Grid>
        {
            items.map(item => {
                return <Grid.Col span={{base: 12, md: 12}}>
                    <BlogItemGeneration 
                        title={item.title}
                        isDraft={isDraft}
                    />
                </Grid.Col>
            })
        }
        </Grid>
    </div>

}