import { api } from "./api"

export const GET_ALL_COUPONS = async () => {
    const {data} = await api.get("/coupons")
    return data.coupons || [];
}

export const GET_COUPOM = async (couponId: string) => {
    const {data} = await api.get(`/coupons/${couponId}`)
    return data;
}

export const DELETE_COUPON = async (id) => {
    const {data} = await api.delete(`/coupons/${id}`)
    return data;
}

export const SAVE_COUPON = async (params : any) => {
    const {data} = await api.post(`/coupons`, params)
    return data;
}
