import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { FaGoogle } from "react-icons/fa6";

interface GroupByFuncProps<T> {
    (item: T): any
}
export const groupBy = <T>(
    list: T[],
    func: GroupByFuncProps<T>,
    keyValidator?: string,
) : [any, T[]][] => {
    const map: any[] = [];
    list.forEach((item) => {
        const key = func(item);
        let i = -1;
        map.forEach((mapItem, index) => {
            if (i === -1 && (
            (mapItem[0] === key)
            || (keyValidator && mapItem[0][keyValidator] === key[keyValidator])
            )) i = index;
        });
        if (i === -1) map.push([key, [item]]);
        else map[i][1].push(item);
    });
    return map;
};

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function formatMoney(value = 0){
  return (value).toLocaleString("en-US", { currency: "USD", style: 'currency', })
}

export const extenseWeekday = (weekday) => [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
][weekday];

export const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
]

export const spentTime = dt => {
    const months = moment().diff(moment(dt), "months")
    const ds = moment().diff(moment(dt), "days")
    const hours = moment().diff(moment(dt), "hours")
    const minutes = moment().diff(moment(dt), "minutes")
    return months > 0
        ? `${months} meses atrás`
        : ds > 0
            ? `${ds} dias atrás`
            : hours > 0 ? `${hours} horas atrás` : `${minutes} minutos atrás`
}

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = React.useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    React.useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }


export const getEntityProfile = item => ({
    "senador": "Senador",
    "deputado_federal": "Deputado Federal",
    "deputado_distrital": "Deputado Distrital",
    "orgao": "Órgão",
}[item.type] || item.type);

export const parseKeyword = (key) => key ? key
  .normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/[^\w\s]/gi, '')
  .toLowerCase() : "";

export const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
    const [interacted, setInteracted] = useState(false);
  
    const toggle = () => setPlaying(!playing);
    const play = () => setPlaying(true);
    const pause = () => setPlaying(false);

    const handleInteraction = () => setInteracted(true);

    const setVolume = volume => {
      audio.volume = volume;
    }
  
    useEffect(() => {
      if(interacted){
        audio.currentTime = 0;
        audio.loop = false;
        playing ? audio.play() : audio.pause();
      }
      }, [playing, interacted]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);

    useEffect(() => {
      document.addEventListener('click', handleInteraction);
      return () => { document.removeEventListener('click', handleInteraction); };
    }, [])
  
    return {playing, play, pause, toggle, setVolume};
};

export const formatMilliseconds = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');
  return parseInt((totalSeconds / 3600).toFixed(0)) > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const getExtenseDatetime = (datetime, hours = true) => {
  const extenseDate = moment(datetime).format("YYYY-MM-DD") === moment().subtract(1, "days").format("YYYY-MM-DD")
    ? "Yesterday"
    : moment(datetime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "Today"
      : moment(datetime).format("MMMM Do YYYY")
  return `${extenseDate}${hours ? `, ${moment(datetime).format("h:mm a")}` : ''}`;
}

export const markdown = (text) => {
  if(!text) return "";
  text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  text = text.replace(/_(.*?)_/g, '<em>$1</em>');
  text = text.replace(/~(.*?)~/g, '<del>$1</del>');
  text = text.replace(/`(.*?)`/g, '<code>$1</code>');
  text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
  text = text.replace(/^\s*-\s(.+)$/gm, '<ul><li>$1</li></ul>');
  text = text.replace(/^\s*\d+\.\s(.+)$/gm, '<ol><li>$1</li></ol>');

  // Handle website links (http/https/www)
  text = text.replace(
    /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+)(?=$|\s)/g,
    (match, before, link) => {
      if (link?.startsWith('www.')) {
        link = 'http://' + link;
      }
      return before + `<a href="${link}" target="_blank">${link}</a>`;
    }
  );

  return ReactHtmlParser(text);
};

export const getStatusColor = status => (
  status?.category === "done" ? "green" : status?.category === "in-progress" ? "violet" : "gray"
)

export const getTypeText = (type : string) => ({
  "text": "Texto",
  "video": "Vídeo",
  "audio": "Áudio",
  "image": "Imagem",
}[type] || "Arquivo")


export function formatFileSize(bytes: number): string {
  if (bytes < 1000) {
    return bytes + ' B';
  } else if (bytes < 1000 * 1000) {
    return (bytes / 1000).toFixed(2) + ' KB';
  } else if (bytes < 1000 * 1000 * 1000) {
    return (bytes / (1000 * 1000)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1000 * 1000 * 1000)).toFixed(2) + ' GB';
  }
}

export function getCurrencies(){
  return [
    { value: "USD", label: "USD - United States Dollar" },
    { value: "AED", label: "AED - United Arab Emirates Dirham" },
    { value: "AFN", label: "AFN - Afghan Afghani" },
    { value: "ALL", label: "ALL - Albanian Lek" },
    { value: "AMD", label: "AMD - Armenian Dram" },
    { value: "ANG", label: "ANG - Netherlands Antillean Guilder" },
    { value: "AOA", label: "AOA - Angolan Kwanza" },
    { value: "ARS", label: "ARS - Argentine Peso" },
    { value: "AUD", label: "AUD - Australian Dollar" },
    { value: "AWG", label: "AWG - Aruban Florin" },
    { value: "AZN", label: "AZN - Azerbaijani Manat" },
    { value: "BAM", label: "BAM - Bosnia and Herzegovina Convertible Mark" },
    { value: "BBD", label: "BBD - Barbadian Dollar" },
    { value: "BDT", label: "BDT - Bangladeshi Taka" },
    { value: "BGN", label: "BGN - Bulgarian Lev" },
    { value: "BIF", label: "BIF - Burundian Franc" },
    { value: "BMD", label: "BMD - Bermudian Dollar" },
    { value: "BND", label: "BND - Brunei Dollar" },
    { value: "BOB", label: "BOB - Bolivian Boliviano" },
    { value: "BRL", label: "BRL - Brazilian Real" },
    { value: "BSD", label: "BSD - Bahamian Dollar" },
    { value: "BWP", label: "BWP - Botswanan Pula" },
    { value: "BYN", label: "BYN - Belarusian Ruble" },
    { value: "BZD", label: "BZD - Belize Dollar" },
    { value: "CAD", label: "CAD - Canadian Dollar" },
    { value: "CDF", label: "CDF - Congolese Franc" },
    { value: "CHF", label: "CHF - Swiss Franc" },
    { value: "CLP", label: "CLP - Chilean Peso" },
    { value: "CNY", label: "CNY - Chinese Yuan" },
    { value: "COP", label: "COP - Colombian Peso" },
    { value: "CRC", label: "CRC - Costa Rican Colón" },
    { value: "CVE", label: "CVE - Cape Verdean Escudo" },
    { value: "CZK", label: "CZK - Czech Republic Koruna" },
    { value: "DJF", label: "DJF - Djiboutian Franc" },
    { value: "DKK", label: "DKK - Danish Krone" },
    { value: "DOP", label: "DOP - Dominican Peso" },
    { value: "DZD", label: "DZD - Algerian Dinar" },
    { value: "EGP", label: "EGP - Egyptian Pound" },
    { value: "ETB", label: "ETB - Ethiopian Birr" },
    { value: "EUR", label: "EUR - Euro" },
    { value: "FJD", label: "FJD - Fijian Dollar" },
    { value: "FKP", label: "FKP - Falkland Islands Pound" },
    { value: "GBP", label: "GBP - British Pound Sterling" },
    { value: "GEL", label: "GEL - Georgian Lari" },
    { value: "GIP", label: "GIP - Gibraltar Pound" },
    { value: "GMD", label: "GMD - Gambian Dalasi" },
    { value: "GNF", label: "GNF - Guinean Franc" },
    { value: "GTQ", label: "GTQ - Guatemalan Quetzal" },
    { value: "GYD", label: "GYD - Guyanese Dollar" },
    { value: "HKD", label: "HKD - Hong Kong Dollar" },
    { value: "HNL", label: "HNL - Honduran Lempira" },
    { value: "HTG", label: "HTG - Haitian Gourde" },
    { value: "HUF", label: "HUF - Hungarian Forint" },
    { value: "IDR", label: "IDR - Indonesian Rupiah" },
    { value: "ILS", label: "ILS - Israeli New Shekel" },
    { value: "INR", label: "INR - Indian Rupee" },
    { value: "ISK", label: "ISK - Icelandic Króna" },
    { value: "JMD", label: "JMD - Jamaican Dollar" },
    { value: "JPY", label: "JPY - Japanese Yen" },
    { value: "KES", label: "KES - Kenyan Shilling" },
    { value: "KGS", label: "KGS - Kyrgyzstani Som" },
    { value: "KHR", label: "KHR - Cambodian Riel" },
    { value: "KMF", label: "KMF - Comorian Franc" },
    { value: "KRW", label: "KRW - South Korean Won" },
    { value: "KYD", label: "KYD - Cayman Islands Dollar" },
    { value: "KZT", label: "KZT - Kazakhstani Tenge" },
    { value: "LAK", label: "LAK - Lao Kip" },
    { value: "LBP", label: "LBP - Lebanese Pound" },
    { value: "LKR", label: "LKR - Sri Lankan Rupee" },
    { value: "LRD", label: "LRD - Liberian Dollar" },
    { value: "LSL", label: "LSL - Lesotho Loti" },
    { value: "MAD", label: "MAD - Moroccan Dirham" },
    { value: "MDL", label: "MDL - Moldovan Leu" },
    { value: "MGA", label: "MGA - Malagasy Ariary" },
    { value: "MKD", label: "MKD - Macedonian Denar" },
    { value: "MMK", label: "MMK - Myanma Kyat" },
    { value: "MNT", label: "MNT - Mongolian Tögrög" },
    { value: "MOP", label: "MOP - Macanese Pataca" },
    { value: "MUR", label: "MUR - Mauritian Rupee" },
    { value: "MVR", label: "MVR - Maldivian Rufiyaa" },
    { value: "MWK", label: "MWK - Malawian Kwacha" },
    { value: "MXN", label: "MXN - Mexican Peso" },
    { value: "MYR", label: "MYR - Malaysian Ringgit" },
    { value: "MZN", label: "MZN - Mozambican Metical" },
    { value: "NAD", label: "NAD - Namibian Dollar" },
    { value: "NGN", label: "NGN - Nigerian Naira" },
    { value: "NIO", label: "NIO - Nicaraguan Córdoba" },
    { value: "NOK", label: "NOK - Norwegian Krone" },
    { value: "NPR", label: "NPR - Nepalese Rupee" },
    { value: "NZD", label: "NZD - New Zealand Dollar" },
    { value: "PAB", label: "PAB - Panamanian Balboa" },
    { value: "PEN", label: "PEN - Peruvian Nuevo Sol" },
    { value: "PGK", label: "PGK - Papua New Guinean Kina" },
    { value: "PHP", label: "PHP - Philippine Peso" },
    { value: "PKR", label: "PKR - Pakistani Rupee" },
    { value: "PLN", label: "PLN - Polish Złoty" },
    { value: "PYG", label: "PYG - Paraguayan Guarani" },
    { value: "QAR", label: "QAR - Qatari Riyal" },
    { value: "RON", label: "RON - Romanian Leu" },
    { value: "RSD", label: "RSD - Serbian Dinar" },
    { value: "RUB", label: "RUB - Russian Ruble" },
    { value: "RWF", label: "RWF - Rwandan Franc" },
    { value: "SAR", label: "SAR - Saudi Riyal" },
    { value: "SBD", label: "SBD - Solomon Islands Dollar" },
    { value: "SCR", label: "SCR - Seychellois Rupee" },
    { value: "SEK", label: "SEK - Swedish Krona" },
    { value: "SGD", label: "SGD - Singapore Dollar" },
    { value: "SHP", label: "SHP - Saint Helena Pound" },
    { value: "SLE", label: "SLE - Sierra Leonean Leone" },
    { value: "SOS", label: "SOS - Somali Shilling" },
    { value: "SRD", label: "SRD - Surinamese Dollar" },
    { value: "STD", label: "STD - São Tomé and Príncipe Dobra" },
    { value: "SZL", label: "SZL - Swazi Lilangeni" },
    { value: "THB", label: "THB - Thai Baht" },
    { value: "TJS", label: "TJS - Tajikistani Somoni" },
    { value: "TOP", label: "TOP - Tongan Pa'anga" },
    { value: "TRY", label: "TRY - Turkish Lira" },
    { value: "TTD", label: "TTD - Trinidad and Tobago Dollar" },
    { value: "TWD", label: "TWD - New Taiwan Dollar" },
    { value: "TZS", label: "TZS - Tanzanian Shilling" },
    { value: "UAH", label: "UAH - Ukrainian Hryvnia" },
    { value: "UGX", label: "UGX - Ugandan Shilling" },
    { value: "UYU", label: "UYU - Uruguayan Peso" },
    { value: "UZS", label: "UZS - Uzbekistani Som" },
    { value: "VND", label: "VND - Vietnamese Dong" },
    { value: "VUV", label: "VUV - Vanuatu Vatu" },
    { value: "WST", label: "WST - Samoan Tala" },
    { value: "XAF", label: "XAF - Central African CFA Franc" },
    { value: "XCD", label: "XCD - East Caribbean Dollar" },
    { value: "XOF", label: "XOF - West African CFA Franc" },
    { value: "XPF", label: "XPF - CFP Franc" },
    { value: "YER", label: "YER - Yemeni Rial" },
    { value: "ZAR", label: "ZAR - South African Rand" },
    { value: "ZMW", label: "ZMW - Zambian Kwacha" },
  ]
}

export const countriesList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Côte d\'Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea, Democratic People\'s Republic of', value: 'KP' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao People\'s Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia & South Sandwich Islands', value: 'GS' },
  { label: 'South Korea', value: 'KR' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard & Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syria', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad & Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks & Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'U.S. Outlying Islands', value: 'UM' },
  { label: 'U.S. Virgin Islands', value: 'VI' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United States', value: 'US' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vatican City', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Wallis & Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' }
]

export const phoneCountryCodes = [
  { "label": "(+93) Afghanistan", "value": "93" },
  { "label": "(+355) Albania", "value": "355" },
  { "label": "(+213) Algeria", "value": "213" },
  { "label": "(+376) Andorra", "value": "376" },
  { "label": "(+244) Angola", "value": "244" },
  { "label": "(+54) Argentina", "value": "54" },
  { "label": "(+374) Armenia", "value": "374" },
  { "label": "(+61) Australia", "value": "61" },
  { "label": "(+43) Austria", "value": "43" },
  { "label": "(+994) Azerbaijan", "value": "994" },
  { "label": "(+973) Bahrain", "value": "973" },
  { "label": "(+880) Bangladesh", "value": "880" },
  { "label": "(+375) Belarus", "value": "375" },
  { "label": "(+32) Belgium", "value": "32" },
  { "label": "(+501) Belize", "value": "501" },
  { "label": "(+229) Benin", "value": "229" },
  { "label": "(+975) Bhutan", "value": "975" },
  { "label": "(+591) Bolivia", "value": "591" },
  { "label": "(+387) Bosnia and Herzegovina", "value": "387" },
  { "label": "(+267) Botswana", "value": "267" },
  { "label": "(+55) Brazil", "value": "55" },
  { "label": "(+673) Brunei", "value": "673" },
  { "label": "(+359) Bulgaria", "value": "359" },
  { "label": "(+226) Burkina Faso", "value": "226" },
  { "label": "(+257) Burundi", "value": "257" },
  { "label": "(+855) Cambodia", "value": "855" },
  { "label": "(+237) Cameroon", "value": "237" },
  { "label": "(+238) Cape Verde", "value": "238" },
  { "label": "(+236) Central African Republic", "value": "236" },
  { "label": "(+235) Chad", "value": "235" },
  { "label": "(+56) Chile", "value": "56" },
  { "label": "(+86) China", "value": "86" },
  { "label": "(+57) Colombia", "value": "57" },
  { "label": "(+269) Comoros", "value": "269" },
  { "label": "(+506) Costa Rica", "value": "506" },
  { "label": "(+225) Côte d'Ivoire", "value": "225" },
  { "label": "(+385) Croatia", "value": "385" },
  { "label": "(+53) Cuba", "value": "53" },
  { "label": "(+357) Cyprus", "value": "357" },
  { "label": "(+420) Czech Republic", "value": "420" },
  { "label": "(+243) Democratic Republic of the Congo", "value": "243" },
  { "label": "(+45) Denmark", "value": "45" },
  { "label": "(+253) Djibouti", "value": "253" },
  { "label": "(+593) Ecuador", "value": "593" },
  { "label": "(+20) Egypt", "value": "20" },
  { "label": "(+503) El Salvador", "value": "503" },
  { "label": "(+240) Equatorial Guinea", "value": "240" },
  { "label": "(+291) Eritrea", "value": "291" },
  { "label": "(+372) Estonia", "value": "372" },
  { "label": "(+251) Ethiopia", "value": "251" },
  { "label": "(+679) Fiji", "value": "679" },
  { "label": "(+358) Finland", "value": "358" },
  { "label": "(+33) France", "value": "33" },
  { "label": "(+241) Gabon", "value": "241" },
  { "label": "(+220) Gambia", "value": "220" },
  { "label": "(+995) Georgia", "value": "995" },
  { "label": "(+49) Germany", "value": "49" },
  { "label": "(+233) Ghana", "value": "233" },
  { "label": "(+30) Greece", "value": "30" },
  { "label": "(+502) Guatemala", "value": "502" },
  { "label": "(+224) Guinea", "value": "224" },
  { "label": "(+245) Guinea-Bissau", "value": "245" },
  { "label": "(+592) Guyana", "value": "592" },
  { "label": "(+509) Haiti", "value": "509" },
  { "label": "(+504) Honduras", "value": "504" },
  { "label": "(+852) Hong Kong", "value": "852" },
  { "label": "(+36) Hungary", "value": "36" },
  { "label": "(+354) Iceland", "value": "354" },
  { "label": "(+91) India", "value": "91" },
  { "label": "(+62) Indonesia", "value": "62" },
  { "label": "(+98) Iran", "value": "98" },
  { "label": "(+964) Iraq", "value": "964" },
  { "label": "(+353) Ireland", "value": "353" },
  { "label": "(+972) Israel", "value": "972" },
  { "label": "(+39) Italy", "value": "39" },
  { "label": "(+81) Japan", "value": "81" },
  { "label": "(+962) Jordan", "value": "962" },
  { "label": "(+254) Kenya", "value": "254" },
  { "label": "(+686) Kiribati", "value": "686" },
  { "label": "(+965) Kuwait", "value": "965" },
  { "label": "(+996) Kyrgyzstan", "value": "996" },
  { "label": "(+856) Laos", "value": "856" },
  { "label": "(+371) Latvia", "value": "371" },
  { "label": "(+961) Lebanon", "value": "961" },
  { "label": "(+266) Lesotho", "value": "266" },
  { "label": "(+231) Liberia", "value": "231" },
  { "label": "(+218) Libya", "value": "218" },
  { "label": "(+423) Liechtenstein", "value": "423" },
  { "label": "(+370) Lithuania", "value": "370" },
  { "label": "(+352) Luxembourg", "value": "352" },
  { "label": "(+853) Macau", "value": "853" },
  { "label": "(+389) Macedonia", "value": "389" },
  { "label": "(+261) Madagascar", "value": "261" },
  { "label": "(+265) Malawi", "value": "265" },
  { "label": "(+60) Malaysia", "value": "60" },
  { "label": "(+960) Maldives", "value": "960" },
  { "label": "(+223) Mali", "value": "223" },
  { "label": "(+356) Malta", "value": "356" },
  { "label": "(+692) Marshall Islands", "value": "692" },
  { "label": "(+222) Mauritania", "value": "222" },
  { "label": "(+230) Mauritius", "value": "230" },
  { "label": "(+52) Mexico", "value": "52" },
  { "label": "(+691) Micronesia", "value": "691" },
  { "label": "(+373) Moldova", "value": "373" },
  { "label": "(+377) Monaco", "value": "377" },
  { "label": "(+976) Mongolia", "value": "976" },
  { "label": "(+382) Montenegro", "value": "382" },
  { "label": "(+212) Morocco", "value": "212" },
  { "label": "(+258) Mozambique", "value": "258" },
  { "label": "(+95) Myanmar", "value": "95" },
  { "label": "(+264) Namibia", "value": "264" },
  { "label": "(+674) Nauru", "value": "674" },
  { "label": "(+977) Nepal", "value": "977" },
  { "label": "(+31) Netherlands", "value": "31" },
  { "label": "(+64) New Zealand", "value": "64" },
  { "label": "(+505) Nicaragua", "value": "505" },
  { "label": "(+227) Niger", "value": "227" },
  { "label": "(+234) Nigeria", "value": "234" },
  { "label": "(+850) North Korea", "value": "850" },
  { "label": "(+47) Norway", "value": "47" },
  { "label": "(+968) Oman", "value": "968" },
  { "label": "(+92) Pakistan", "value": "92" },
  { "label": "(+680) Palau", "value": "680" },
  { "label": "(+970) Palestine", "value": "970" },
  { "label": "(+507) Panama", "value": "507" },
  { "label": "(+675) Papua New Guinea", "value": "675" },
  { "label": "(+595) Paraguay", "value": "595" },
  { "label": "(+51) Peru", "value": "51" },
  { "label": "(+63) Philippines", "value": "63" },
  { "label": "(+48) Poland", "value": "48" },
  { "label": "(+351) Portugal", "value": "351" },
  { "label": "(+974) Qatar", "value": "974" },
  { "label": "(+242) Republic of the Congo", "value": "242" },
  { "label": "(+40) Romania", "value": "40" },
  { "label": "(+7) Russia", "value": "7" },
  { "label": "(+250) Rwanda", "value": "250" },
  { "label": "(+685) Samoa", "value": "685" },
  { "label": "(+378) San Marino", "value": "378" },
  { "label": "(+239) São Tomé and Príncipe", "value": "239" },
  { "label": "(+966) Saudi Arabia", "value": "966" },
  { "label": "(+221) Senegal", "value": "221" },
  { "label": "(+381) Serbia", "value": "381" },
  { "label": "(+248) Seychelles", "value": "248" },
  { "label": "(+232) Sierra Leone", "value": "232" },
  { "label": "(+65) Singapore", "value": "65" },
  { "label": "(+421) Slovakia", "value": "421" },
  { "label": "(+386) Slovenia", "value": "386" },
  { "label": "(+677) Solomon Islands", "value": "677" },
  { "label": "(+252) Somalia", "value": "252" },
  { "label": "(+27) South Africa", "value": "27" },
  { "label": "(+82) South Korea", "value": "82" },
  { "label": "(+211) South Sudan", "value": "211" },
  { "label": "(+34) Spain", "value": "34" },
  { "label": "(+94) Sri Lanka", "value": "94" },
  { "label": "(+249) Sudan", "value": "249" },
  { "label": "(+597) Suriname", "value": "597" },
  { "label": "(+268) Swaziland", "value": "268" },
  { "label": "(+46) Sweden", "value": "46" },
  { "label": "(+41) Switzerland", "value": "41" },
  { "label": "(+963) Syria", "value": "963" },
  { "label": "(+886) Taiwan", "value": "886" },
  { "label": "(+992) Tajikistan", "value": "992" },
  { "label": "(+255) Tanzania", "value": "255" },
  { "label": "(+66) Thailand", "value": "66" },
  { "label": "(+670) Timor-Leste", "value": "670" },
  { "label": "(+228) Togo", "value": "228" },
  { "label": "(+676) Tonga", "value": "676" },
  { "label": "(+1868) Trinidad and Tobago", "value": "1868" },
  { "label": "(+216) Tunisia", "value": "216" },
  { "label": "(+90) Turkey", "value": "90" },
  { "label": "(+993) Turkmenistan", "value": "993" },
  { "label": "(+688) Tuvalu", "value": "688" },
  { "label": "(+256) Uganda", "value": "256" },
  { "label": "(+380) Ukraine", "value": "380" },
  { "label": "(+971) United Arab Emirates", "value": "971" },
  { "label": "(+44) United Kingdom", "value": "44" },
  { "label": "(+1) United States of America", "value": "1" },
  { "label": "(+598) Uruguay", "value": "598" },
  { "label": "(+998) Uzbekistan", "value": "998" },
  { "label": "(+678) Vanuatu", "value": "678" },
  { "label": "(+379) Vatican City", "value": "379" },
  { "label": "(+58) Venezuela", "value": "58" },
  { "label": "(+84) Vietnam", "value": "84" },
  { "label": "(+967) Yemen", "value": "967" },
  { "label": "(+260) Zambia", "value": "260" },
  { "label": "(+263) Zimbabwe", "value": "263" }
]

export const extenseRecurrenceType = (type: string) => ({
  "month": "Monthly",
  "week": "Weekly",
  "year": "Annually",
}[type] || "Monthly")

export const sectorList = [
  {label: "Mining", value: "Mining"},
  {label: "Educational Services", value: "Educational Services"},
  {label: "Agriculture, Forestry, Fishing and Hunting", value: "Agriculture, Forestry, Fishing and Hunting"},
  {label: "Accommodation and Food Services", value: "Accommodation and Food Services"},
  {label: "Real Estate and Rental and Leasing", value: "Real Estate and Rental and Leasing"},
  {label: "Other Services (except Public Administration)", value: "Other Services (except Public Administration)"},
  {label: "Arts, Entertainment and Recreation", value: "Arts, Entertainment and Recreation"},
  {label: "Healthcare and Social Assistance", value: "Healthcare and Social Assistance"},
  {label: "Professional, Scientific and Technical Services", value: "Professional, Scientific and Technical Services"},
  {label: "Administration, Business Support and Waste Management Services", value: "Administration, Business Support and Waste Management Services"},
  {label: "Information", value: "Information"},
  {label: "Transportation and Warehousing", value: "Transportation and Warehousing"},
  {label: "Construction", value: "Construction"},
  {label: "Retail Trade", value: "Retail Trade"},
  {label: "Wholesale Trade", value: "Wholesale Trade"},
  {label: "Utilities", value: "Utilities"},
  {label: "Finance and Insurance", value: "Finance and Insurance"},
  {label: "Manufacturing", value: "Manufacturing"},
  {label: "Technology", value: "Technology"},
  {label: "Online Retail", value: "Online Retail"},
  {label: "Industrial Machinery, Gas and Chemicals", value: "Industrial Machinery, Gas and Chemicals"},
  {label: "Specialist Engineering, Infrastructure and Contractors", value: "Specialist Engineering, Infrastructure and Contractors"},
  {label: "Advisory and Financial Services", value: "Advisory and Financial Services"},
  {label: "Life Sciences", value: "Life Sciences"},
  {label: "Business Franchises", value: "Business Franchises"},
  {label: "Consumer Goods and Services", value: "Consumer Goods and Services"},
].sort((a,b) => a.label > b.label ? 1 : -1)

export const formatLabel = txt => (txt ?? "")
  .toLowerCase()
  .replace(/ /g, "-")
  .replace(/\.|\,|\$|\||\(|\)|\%/g, "")
  .replace(/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
  , "")
  .split("-").filter(nn => nn).join("-");

// ScheilaSMTy https://storage.googleapis.com/eleven-public-prod/UfR91GZFReU9KE7HWeOrpPEKpG73/voices/cyD08lEy76q03ER1jZ7y/4cb10850-1932-48bf-a88d-600839c19c80.mp3

export const timezones = [
    {
      "value": "Dateline Standard Time",
      "abbr": "DST",
      "offset": -12,
      "isdst": false,
      "text": "(UTC-12:00) International Date Line West",
      "utc": [
        "Etc/GMT+12"
      ]
    },
    {
      "value": "UTC-11",
      "abbr": "U",
      "offset": -11,
      "isdst": false,
      "text": "(UTC-11:00) Coordinated Universal Time-11",
      "utc": [
        "Etc/GMT+11",
        "Pacific/Midway",
        "Pacific/Niue",
        "Pacific/Pago_Pago"
      ]
    },
    {
      "value": "Hawaiian Standard Time",
      "abbr": "HST",
      "offset": -10,
      "isdst": false,
      "text": "(UTC-10:00) Hawaii",
      "utc": [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti"
      ]
    },
    {
      "value": "Alaskan Standard Time",
      "abbr": "AKDT",
      "offset": -8,
      "isdst": true,
      "text": "(UTC-09:00) Alaska",
      "utc": [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat"
      ]
    },
    {
      "value": "Pacific Standard Time (Mexico)",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-08:00) Baja California",
      "utc": [
        "America/Santa_Isabel"
      ]
    },
    {
      "value": "Pacific Daylight Time",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-07:00) Pacific Daylight Time (US & Canada)",
      "utc": [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver"
      ]
    },
    {
      "value": "Pacific Standard Time",
      "abbr": "PST",
      "offset": -8,
      "isdst": false,
      "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
      "utc": [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "PST8PDT"
      ]
    },
    {
      "value": "US Mountain Standard Time",
      "abbr": "UMST",
      "offset": -7,
      "isdst": false,
      "text": "(UTC-07:00) Arizona",
      "utc": [
        "America/Creston",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "America/Whitehorse",
        "Etc/GMT+7"
      ]
    },
    {
      "value": "Mountain Standard Time (Mexico)",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      "utc": [
        "America/Chihuahua",
        "America/Mazatlan"
      ]
    },
    {
      "value": "Mountain Standard Time",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Mountain Time (US & Canada)",
      "utc": [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT"
      ]
    },
    {
      "value": "Central America Standard Time",
      "abbr": "CAST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Central America",
      "utc": [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos"
      ]
    },
    {
      "value": "Central Standard Time",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Central Time (US & Canada)",
      "utc": [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT"
      ]
    },
    {
      "value": "Central Standard Time (Mexico)",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      "utc": [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey"
      ]
    },
    {
      "value": "Canada Central Standard Time",
      "abbr": "CCST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Saskatchewan",
      "utc": [
        "America/Regina",
        "America/Swift_Current"
      ]
    },
    {
      "value": "SA Pacific Standard Time",
      "abbr": "SPST",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Bogota, Lima, Quito",
      "utc": [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5"
      ]
    },
    {
      "value": "Eastern Standard Time",
      "abbr": "EST",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Eastern Time (US & Canada)",
      "utc": [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto"
      ]
    },
    {
      "value": "Eastern Daylight Time",
      "abbr": "EDT",
      "offset": -4,
      "isdst": true,
      "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
      "utc": [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto"
      ]
    },
    {
      "value": "US Eastern Standard Time",
      "abbr": "UEDT",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Indiana (East)",
      "utc": [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis"
      ]
    },
    {
      "value": "Venezuela Standard Time",
      "abbr": "VST",
      "offset": -4.5,
      "isdst": false,
      "text": "(UTC-04:30) Caracas",
      "utc": [
        "America/Caracas"
      ]
    },
    {
      "value": "Paraguay Standard Time",
      "abbr": "PYT",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Asuncion",
      "utc": [
        "America/Asuncion"
      ]
    },
    {
      "value": "Atlantic Standard Time",
      "abbr": "ADT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-04:00) Atlantic Time (Canada)",
      "utc": [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda"
      ]
    },
    {
      "value": "Central Brazilian Standard Time",
      "abbr": "CBST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Cuiaba",
      "utc": [
        "America/Campo_Grande",
        "America/Cuiaba"
      ]
    },
    {
      "value": "SA Western Standard Time",
      "abbr": "SWST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      "utc": [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4"
      ]
    },
    {
      "value": "Pacific SA Standard Time",
      "abbr": "PSST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Santiago",
      "utc": [
        "America/Santiago",
        "Antarctica/Palmer"
      ]
    },
    {
      "value": "Newfoundland Standard Time",
      "abbr": "NDT",
      "offset": -2.5,
      "isdst": true,
      "text": "(UTC-03:30) Newfoundland",
      "utc": [
        "America/St_Johns"
      ]
    },
    {
      "value": "E. South America Standard Time",
      "abbr": "ESAST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Brasilia",
      "utc": [
        "America/Sao_Paulo"
      ]
    },
    {
      "value": "Argentina Standard Time",
      "abbr": "AST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Buenos Aires",
      "utc": [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza"
      ]
    },
    {
      "value": "SA Eastern Standard Time",
      "abbr": "SEST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Cayenne, Fortaleza",
      "utc": [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3"
      ]
    },
    {
      "value": "Greenland Standard Time",
      "abbr": "GDT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-03:00) Greenland",
      "utc": [
        "America/Godthab"
      ]
    },
    {
      "value": "Montevideo Standard Time",
      "abbr": "MST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Montevideo",
      "utc": [
        "America/Montevideo"
      ]
    },
    {
      "value": "Bahia Standard Time",
      "abbr": "BST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Salvador",
      "utc": [
        "America/Bahia"
      ]
    },
    {
      "value": "UTC-02",
      "abbr": "U",
      "offset": -2,
      "isdst": false,
      "text": "(UTC-02:00) Coordinated Universal Time-02",
      "utc": [
        "America/Noronha",
        "Atlantic/South_Georgia",
        "Etc/GMT+2"
      ]
    },
    {
      "value": "Mid-Atlantic Standard Time",
      "abbr": "MDT",
      "offset": -1,
      "isdst": true,
      "text": "(UTC-02:00) Mid-Atlantic - Old",
      "utc": []
    },
    {
      "value": "Azores Standard Time",
      "abbr": "ADT",
      "offset": 0,
      "isdst": true,
      "text": "(UTC-01:00) Azores",
      "utc": [
        "America/Scoresbysund",
        "Atlantic/Azores"
      ]
    },
    {
      "value": "Cape Verde Standard Time",
      "abbr": "CVST",
      "offset": -1,
      "isdst": false,
      "text": "(UTC-01:00) Cape Verde Is.",
      "utc": [
        "Atlantic/Cape_Verde",
        "Etc/GMT+1"
      ]
    },
    {
      "value": "Morocco Standard Time",
      "abbr": "MDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Casablanca",
      "utc": [
        "Africa/Casablanca",
        "Africa/El_Aaiun"
      ]
    },
    {
      "value": "UTC",
      "abbr": "UTC",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Coordinated Universal Time",
      "utc": [
        "America/Danmarkshavn",
        "Etc/GMT"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GMT",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "British Summer Time",
      "abbr": "BST",
      "offset": 1,
      "isdst": true,
      "text": "(UTC+01:00) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Dublin, Lisbon",
      "utc": [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon"
      ]
    },
    {
      "value": "Greenwich Standard Time",
      "abbr": "GST",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Monrovia, Reykjavik",
      "utc": [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena"
      ]
    },
    {
      "value": "W. Europe Standard Time",
      "abbr": "WEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      "utc": [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich"
      ]
    },
    {
      "value": "Central Europe Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "utc": [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane"
      ]
    },
    {
      "value": "Romance Standard Time",
      "abbr": "RDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      "utc": [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris"
      ]
    },
    {
      "value": "Central European Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      "utc": [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb"
      ]
    },
    {
      "value": "W. Central Africa Standard Time",
      "abbr": "WCAST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) West Central Africa",
      "utc": [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1"
      ]
    },
    {
      "value": "Namibia Standard Time",
      "abbr": "NST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) Windhoek",
      "utc": [
        "Africa/Windhoek"
      ]
    },
    {
      "value": "GTB Standard Time",
      "abbr": "GDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Athens, Bucharest",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau"
      ]
    },
    {
      "value": "Middle East Standard Time",
      "abbr": "MEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Beirut",
      "utc": [
        "Asia/Beirut"
      ]
    },
    {
      "value": "Egypt Standard Time",
      "abbr": "EST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Cairo",
      "utc": [
        "Africa/Cairo"
      ]
    },
    {
      "value": "Syria Standard Time",
      "abbr": "SDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Damascus",
      "utc": [
        "Asia/Damascus"
      ]
    },
    {
      "value": "E. Europe Standard Time",
      "abbr": "EEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) E. Europe",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kyiv",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
  
      ]
    },
    {
      "value": "South Africa Standard Time",
      "abbr": "SAST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Harare, Pretoria",
      "utc": [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2"
      ]
    },
    {
      "value": "FLE Standard Time",
      "abbr": "FDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      "utc": [
        "Europe/Helsinki",
        "Europe/Kyiv",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
      ]
    },
    {
      "value": "Turkey Standard Time",
      "abbr": "TDT",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Istanbul",
      "utc": [
        "Europe/Istanbul"
      ]
    },
    {
      "value": "Israel Standard Time",
      "abbr": "JDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Jerusalem",
      "utc": [
        "Asia/Jerusalem"
      ]
    },
    {
      "value": "Libya Standard Time",
      "abbr": "LST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Tripoli",
      "utc": [
        "Africa/Tripoli"
      ]
    },
    {
      "value": "Jordan Standard Time",
      "abbr": "JST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Amman",
      "utc": [
        "Asia/Amman"
      ]
    },
    {
      "value": "Arabic Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Baghdad",
      "utc": [
        "Asia/Baghdad"
      ]
    },
    {
      "value": "Kaliningrad Standard Time",
      "abbr": "KST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+02:00) Kaliningrad",
      "utc": [
        "Europe/Kaliningrad"
      ]
    },
    {
      "value": "Arab Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Kuwait, Riyadh",
      "utc": [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh"
      ]
    },
    {
      "value": "E. Africa Standard Time",
      "abbr": "EAST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Nairobi",
      "utc": [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte"
      ]
    },
    {
      "value": "Moscow Standard Time",
      "abbr": "MSK",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      "utc": [
          "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk"
      ]
    },
    {
      "value": "Samara Time",
      "abbr": "SAMT",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      "utc": [
          "Europe/Astrakhan",
        "Europe/Samara",
          "Europe/Ulyanovsk"
      ]
    },
    {
      "value": "Iran Standard Time",
      "abbr": "IDT",
      "offset": 4.5,
      "isdst": true,
      "text": "(UTC+03:30) Tehran",
      "utc": [
        "Asia/Tehran"
      ]
    },
    {
      "value": "Arabian Standard Time",
      "abbr": "AST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Abu Dhabi, Muscat",
      "utc": [
        "Asia/Dubai",
        "Asia/Muscat",
        "Etc/GMT-4"
      ]
    },
    {
      "value": "Azerbaijan Standard Time",
      "abbr": "ADT",
      "offset": 5,
      "isdst": true,
      "text": "(UTC+04:00) Baku",
      "utc": [
        "Asia/Baku"
      ]
    },
    {
      "value": "Mauritius Standard Time",
      "abbr": "MST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Port Louis",
      "utc": [
        "Indian/Mahe",
        "Indian/Mauritius",
        "Indian/Reunion"
      ]
    },
    {
      "value": "Georgian Standard Time",
      "abbr": "GET",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Tbilisi",
      "utc": [
        "Asia/Tbilisi"
      ]
    },
    {
      "value": "Caucasus Standard Time",
      "abbr": "CST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Yerevan",
      "utc": [
        "Asia/Yerevan"
      ]
    },
    {
      "value": "Afghanistan Standard Time",
      "abbr": "AST",
      "offset": 4.5,
      "isdst": false,
      "text": "(UTC+04:30) Kabul",
      "utc": [
        "Asia/Kabul"
      ]
    },
    {
      "value": "West Asia Standard Time",
      "abbr": "WAST",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Ashgabat, Tashkent",
      "utc": [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives"
      ]
    },
    {
      "value": "Yekaterinburg Time",
      "abbr": "YEKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Yekaterinburg",
      "utc": [
        "Asia/Yekaterinburg"
      ]
    },
    {
      "value": "Pakistan Standard Time",
      "abbr": "PKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Islamabad, Karachi",
      "utc": [
        "Asia/Karachi"
      ]
    },
    {
      "value": "India Standard Time",
      "abbr": "IST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      "utc": [
        "Asia/Kolkata",
        "Asia/Calcutta"
      ]
    },
    {
      "value": "Sri Lanka Standard Time",
      "abbr": "SLST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Sri Jayawardenepura",
      "utc": [
        "Asia/Colombo"
      ]
    },
    {
      "value": "Nepal Standard Time",
      "abbr": "NST",
      "offset": 5.75,
      "isdst": false,
      "text": "(UTC+05:45) Kathmandu",
      "utc": [
        "Asia/Kathmandu"
      ]
    },
    {
      "value": "Central Asia Standard Time",
      "abbr": "CAST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Nur-Sultan (Astana)",
      "utc": [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos"
      ]
    },
    {
      "value": "Bangladesh Standard Time",
      "abbr": "BST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Dhaka",
      "utc": [
        "Asia/Dhaka",
        "Asia/Thimphu"
      ]
    },
    {
      "value": "Myanmar Standard Time",
      "abbr": "MST",
      "offset": 6.5,
      "isdst": false,
      "text": "(UTC+06:30) Yangon (Rangoon)",
      "utc": [
        "Asia/Rangoon",
        "Indian/Cocos"
      ]
    },
    {
      "value": "SE Asia Standard Time",
      "abbr": "SAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      "utc": [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas"
      ]
    },
    {
      "value": "N. Central Asia Standard Time",
      "abbr": "NCAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Novosibirsk",
      "utc": [
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk"
      ]
    },
    {
      "value": "China Standard Time",
      "abbr": "CST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      "utc": [
        "Asia/Hong_Kong",
        "Asia/Macau",
        "Asia/Shanghai"
      ]
    },
    {
      "value": "North Asia Standard Time",
      "abbr": "NAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Krasnoyarsk",
      "utc": [
        "Asia/Krasnoyarsk"
      ]
    },
    {
      "value": "Singapore Standard Time",
      "abbr": "MPST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Kuala Lumpur, Singapore",
      "utc": [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8"
      ]
    },
    {
      "value": "W. Australia Standard Time",
      "abbr": "WAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Perth",
      "utc": [
        "Antarctica/Casey",
        "Australia/Perth"
      ]
    },
    {
      "value": "Taipei Standard Time",
      "abbr": "TST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Taipei",
      "utc": [
        "Asia/Taipei"
      ]
    },
    {
      "value": "Ulaanbaatar Standard Time",
      "abbr": "UST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Ulaanbaatar",
      "utc": [
        "Asia/Choibalsan",
        "Asia/Ulaanbaatar"
      ]
    },
    {
      "value": "North Asia East Standard Time",
      "abbr": "NAEST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Irkutsk",
      "utc": [
        "Asia/Irkutsk"
      ]
    },
    {
      "value": "Japan Standard Time",
      "abbr": "JST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
      "utc": [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau"
      ]
    },
    {
      "value": "Korea Standard Time",
      "abbr": "KST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Seoul",
      "utc": [
        "Asia/Pyongyang",
        "Asia/Seoul"
      ]
    },
    {
      "value": "Cen. Australia Standard Time",
      "abbr": "CAST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Adelaide",
      "utc": [
        "Australia/Adelaide",
        "Australia/Broken_Hill"
      ]
    },
    {
      "value": "AUS Central Standard Time",
      "abbr": "ACST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Darwin",
      "utc": [
        "Australia/Darwin"
      ]
    },
    {
      "value": "E. Australia Standard Time",
      "abbr": "EAST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Brisbane",
      "utc": [
        "Australia/Brisbane",
        "Australia/Lindeman"
      ]
    },
    {
      "value": "AUS Eastern Standard Time",
      "abbr": "AEST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
      "utc": [
        "Australia/Melbourne",
        "Australia/Sydney"
      ]
    },
    {
      "value": "West Pacific Standard Time",
      "abbr": "WPST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Guam, Port Moresby",
      "utc": [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk"
      ]
    },
    {
      "value": "Tasmania Standard Time",
      "abbr": "TST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Hobart",
      "utc": [
        "Australia/Currie",
        "Australia/Hobart"
      ]
    },
    {
      "value": "Yakutsk Standard Time",
      "abbr": "YST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Yakutsk",
      "utc": [
        "Asia/Chita",
        "Asia/Khandyga",
        "Asia/Yakutsk"
      ]
    },
    {
      "value": "Central Pacific Standard Time",
      "abbr": "CPST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Solomon Is., New Caledonia",
      "utc": [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape"
      ]
    },
    {
      "value": "Vladivostok Standard Time",
      "abbr": "VST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Vladivostok",
      "utc": [
        "Asia/Sakhalin",
        "Asia/Ust-Nera",
        "Asia/Vladivostok"
      ]
    },
    {
      "value": "New Zealand Standard Time",
      "abbr": "NZST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Auckland, Wellington",
      "utc": [
        "Antarctica/McMurdo",
        "Pacific/Auckland"
      ]
    },
    {
      "value": "UTC+12",
      "abbr": "U",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Coordinated Universal Time+12",
      "utc": [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis"
      ]
    },
    {
      "value": "Fiji Standard Time",
      "abbr": "FST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Fiji",
      "utc": [
        "Pacific/Fiji"
      ]
    },
    {
      "value": "Magadan Standard Time",
      "abbr": "MST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Magadan",
      "utc": [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk"
      ]
    },
    {
      "value": "Kamchatka Standard Time",
      "abbr": "KDT",
      "offset": 13,
      "isdst": true,
      "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      "utc": [
        "Asia/Kamchatka"
      ]
    },
    {
      "value": "Tonga Standard Time",
      "abbr": "TST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Nuku'alofa",
      "utc": [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu"
      ]
    },
    {
      "value": "Samoa Standard Time",
      "abbr": "SST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Samoa",
      "utc": [
        "Pacific/Apia"
      ]
    }
]

export const medias = [
  {
    Icon: FaGoogle,
    key: "calendar",
    title: "Google Calendar",
    color: "red",
  }
]

export function toTitleCase(str = "") {
  return str.replace(/\_/g, " ").replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}