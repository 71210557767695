import { Box, Divider, Grid, Group, Paper, Text, UnstyledButton } from '@mantine/core';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Companies } from '../components';
import PlansPage from "./plans";
import CouponsPage from "./coupons";
import AffiliatesPage from "./affiliates";
import Knowledges from "./knowledges";
import BlogGenerator from "./blog_generator";
import { useAuth } from '../contexts/auth.context';

export default function CompaniesPage(){
    const [selected, setSelected] = React.useState("companies");
    const { hash } = useLocation();
    const { userData } = useAuth();
    const navigate = useNavigate();

    const options = [
        {key: "companies", title: "Companies" },
        userData?.user?.isAdmin && {key: "plans", title: "Plans" },
        userData?.user?.isAdmin && {key: "coupons", title: "Coupons" },
        userData?.user?.isAdmin && {key: "affiliates", title: "Affiliates" },
        // userData?.user?.isAdmin?.knowledges && {key: "knowledges", title: "Accelerator" },
        // userData?.user?.isAdmin?.blog_generator && {key: "blog_generator", title: "Blog Generator" },
    ].filter(nn => nn)

    React.useEffect(() => {
        if (hash) {
            setSelected(hash.split("#")[1]);
        }
      }, [hash])

    return <>
        <Paper shadow='xs' style={{padding: 0}}>
            <Grid gutter={0}>
                <Grid.Col span={{ base: 12, md: 4 }} style={{minHeight: '70vh'}}>
                    <Box style={{height: '100%'}}>
                        {options.map(({key, title}) => (
                            <UnstyledButton style={{width: '100%'}} onClick={() => navigate(`/admin#${key}`)}>
                                <Group
                                    style={{borderRight: `7px solid ${selected === key ? "black" : "transparent"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text size="md" fw="bold">{title}</Text>
                                    </Box>
                                </Group>
                                <Divider />
                            </UnstyledButton>
                        ))}
                    </Box>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 8 }} style={{height: '100%', minHeight: '70vh', backgroundColor: '#242442'}}>
                    <Box p="md" pr="xl">
                        {selected === "companies" && <Companies.List />}
                        {userData?.user?.isAdmin && selected === "plans" && <PlansPage />}
                        {userData?.user?.isAdmin && selected === "coupons" && <CouponsPage />}
                        {userData?.user?.isAdmin && selected === "affiliates" && <AffiliatesPage />}
                        {userData?.user?.isAdmin && selected === "knowledges" && <Knowledges />}
                        {userData?.user?.isAdmin && selected === "blog_generator" && <BlogGenerator />}
                    </Box>
                </Grid.Col>
            </Grid>
            </Paper>

    </>
    
}

