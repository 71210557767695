import {
  ActionIcon,
  Box,
  Button,
  Group,
  Menu,
  Modal,
  Text
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useLanguage } from "../../contexts/language.context";
import { timezones } from "../../utility/util";
import InputField, { InputFieldTypes } from "../input_field";
import { useAgentVariables } from "../../contexts/app.context";
import { SAVE_CALENDAR } from "../../services/calendars";
import { showNotification } from "@mantine/notifications";
import Whatsapps from "../integrations/whatsapp";

export default function ActionForm({ data, setData, whatsapps = [], calendars = [], loadWhatsapps, loadCalendars }: any){
    const { str } = useLanguage(); 
    const companyVariables = useAgentVariables();
    const v = data;

    const [newCalendar, setNewCalendar] = useState(null);
    const [openWhatsapps, setOpenWhatsapps] = useState(null);

    const actVariables = [
      ...companyVariables,
      ...(data?.variables ?? [])
        .filter(v => v.key)
        .map(v => ({ label: `Action.${v.key.toLowerCase()}`, value: `Action.${v.key.toLowerCase()}` }))
    ]

    useEffect(() => {
      if(!data?.timezone && ["schedule_a_call", "book_appointment"].includes(data?.type)){
        setData(dt => ({ ...dt, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, }));
      }
    }, [data]);

    return <>
    <InputField
      name="description"
      title={str("AGENTS.ACTION_FORM_WHEN_TRIGGER_TITLE")}
      placeholder={str("AGENTS.ACTION_FORM_WHEN_TRIGGER_PLACEHOLDER")}
      value={data?.description}
      fieldType={InputFieldTypes.TEXTAREA}
      min={3}
      max={3}
      mt="md"
      onChange={({description}) => {
        setData(d => ({...d, description}))
      }}
    />
    {["schedule_a_call"].includes(data?.type) && <>
      {/* <InputField
        name="agent"
        title={str("AGENTS.TITLE")}
        value={data?.agent}
        mt="md"
        onChange={({agent}) => {
          setData(d => ({...d, agent}))
        }}
        options={agents.map(agent => ({
          value: agent._id,
          label: agent.name,
        }))}
        fieldType={InputFieldTypes.SELECT}
      /> */}
      <InputField
        name="timezone"
        title={str("TIMEZONE")}
        value={data?.timezone}
        mt="md"
        onChange={({timezone}) => {
          setData(d => ({...d, timezone}))
        }}
        fieldType={InputFieldTypes.SELECT}
        options={timezones.reduce((pv: any[], tz) => {
          return pv.concat(tz.utc.map(item => ({ value: item, label: `${item}` })))
        }, [])}
      />
    </>}
    {["book_appointment"].includes(data?.type) && <>
      <InputField
        name="calendar"
        title={str("CALENDAR")}
        value={data?.calendar}
        mt="md"
        onChange={({calendar}) => {
          if(calendar === "new-calendar") setNewCalendar({  });
          else setData(d => ({...d, calendar}));
        }}
        fieldType={InputFieldTypes.SELECT}
        options={[
          ...calendars.map(c => ({value: c._id, label: c.title})),
          { value: "new-calendar", label: str("CALENDARS.NEW") },
        ]}
      />
      <InputField
        name="timezone"
        title={str("TIMEZONE")}
        value={data?.timezone}
        mt="md"
        onChange={({timezone}) => {
          setData(d => ({...d, timezone}))
        }}
        fieldType={InputFieldTypes.SELECT}
        options={timezones.reduce((pv: any[], tz) => {
          return pv.concat(tz.utc.map(item => ({ value: item, label: `${item}` })))
        }, [])}
      />
      <InputField
        name="duration"
        title={str("AGENTS.DURATION")}
        value={data?.duration ?? 30}
        mt="md"
        onChange={({duration}) => {
          setData(d => ({...d, duration}))
        }}
        fieldType={InputFieldTypes.NUMBER}
      />
      <InputField
        name="send_email"
        title="Send invitation via e-mail"
        value={data?.send_email ?? true}
        mt="md"
        onChange={({ send_email }) => {
          setData(d => ({...d, send_email }))
        }}
        fieldType={InputFieldTypes.CHECKBOX}
      />
    </>}
    {["call_webhook"].includes(data?.type) && <>
      <Group mt="md">
        <Text fw="bold" style={{flex: 1}}>Variables</Text>
        <ActionIcon
          onClick={() => {
            setData(d => ({...d, variables: [...(data?.variables ?? []), {}] }))
          }}
        ><FaPlus/></ActionIcon>
      </Group>
      {(v.variables ?? []).map((it,ii) => <>
        <Group align="flex-end" mt="md">
          <InputField
            name="key"
            title="Variable Key"
            style={{flex: 0.4}}
            value={it?.key}
            onChange={({key}) => {
              setData(d => ({...d, variables:
                (data?.variables ?? []).map((it2, ij) => ii === ij ? { ...it2, key } : it2)
              }))
            }}
          />  
          <InputField
            name="description"
            title="Description"
            placeholder={str("AGENTS.VARIABLE_DESCRIPTION_PLACEHOLDER")}
            style={{flex: 0.6}}
            value={it?.description}
            onChange={({description}) => {
              setData(d => ({...d, variables:
                (data?.variables ?? []).map((it2, ij) => ii === ij ? { ...it2, description } : it2)
              }))
            }}
          />
          <ActionIcon size="lg" variant="outline" onClick={() => {
            setData(na => ({ ...na, variables: (data?.variables ?? []).filter((it2, ij) => ii !== ij)}))
          }}><FaTrash /></ActionIcon>
        </Group>
      </>)}

      <Group mt="md">
        <InputField
          name="method"
          title="URL"
          value={data?.method ?? "POST"}
          onChange={({method}) => { setData(d => ({...d, method})) }}
          fieldType={InputFieldTypes.SELECT}
          options={[
            { value: "POST", label: "POST" },
            { value: "GET", label: "GET" },
            { value: "DELETE", label: "DELETE" },
            { value: "PATCH", label: "PATCH" },
            { value: "PUT", label: "PUT" },
          ]}
        />
        <InputField
          name="url"
          title="URL"
          style={{flex: 1}}
          value={data?.url}
          onChange={({url}) => { setData(d => ({...d, url})) }}
        />
      </Group>

      <InputField
        name="headers"
        title="Headers (json)"
        mt="md"
        fieldType={InputFieldTypes.TEXTAREA}
        value={data?.headers}
        options={actVariables}
        onChange={({headers}) => { setData(d => ({...d, headers})) }}
      />
      <Menu>
          <Menu.Target><Button variant="outline" color="white" mt="xs" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
          <Menu.Dropdown>
              {
                  (actVariables).map(v => (
                  <Menu.Item onClick={() => {
                      setData(na => ({ ...na, headers: `${na?.headers ?? ""}{{${v.value}}}` }))
                  }}>{v.label}</Menu.Item>
                  ))
              }
          </Menu.Dropdown>
      </Menu>
      {data?.method !== "GET" && <>
        <InputField
          name="data"
          title="Body (json)"
          mt="md"
          fieldType={InputFieldTypes.TEXTAREA}
          value={data?.data}
          options={actVariables}
          onChange={({data}) => { setData(d => ({...d, data})) }}
        />
        <Menu>
          <Menu.Target><Button variant="outline" color="white" mt="xs" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
          <Menu.Dropdown>
              {
                  (actVariables).map(v => (
                  <Menu.Item onClick={() => {
                      setData(na => ({ ...na, data: `${na?.data ?? ""}{{${v.value}}}` }))
                  }}>{v.label}</Menu.Item>
                  ))
              }
          </Menu.Dropdown>
      </Menu>
      </>}
      {data?.method === "GET" && <>
        <InputField
          name="params"
          title="Params (json)"
          mt="md"
          options={actVariables}
          fieldType={InputFieldTypes.TEXTAREA}
          value={data?.params}
          onChange={({params}) => { setData(d => ({...d, params})) }}
        />
        <Menu>
          <Menu.Target><Button variant="outline" color="white" mt="xs" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
          <Menu.Dropdown>
              {
                  (actVariables).map(v => (
                  <Menu.Item onClick={() => {
                      setData(na => ({ ...na, params: `${na?.params ?? ""}{{${v.value}}}` }))
                  }}>{v.label}</Menu.Item>
                  ))
              }
          </Menu.Dropdown>
      </Menu>
      </>}

    </>}
    {["integration", "whatsapp"].includes(data?.type) && <>
      <InputField
        name="origin"
        title="Integration Type"
        value={data?.origin}
        fieldType={InputFieldTypes.SELECT}
        clearable={false}
        searchable={false}
        options={[
          { value: "zapier", label: "Zapier Trigger" },
          { value: "whatsapp", label: "Whatsapp Message" },
          { value: "wati", label: "Wati Message" },
        ]}
        mt="md"
        onChange={({origin}) => {
          let variables: any[] = []
          if(origin === "whatsapp"){
            variables = [{ key: "message", blocked: true }]
          }
          setData(d => ({...d, origin, variables}))
        }}
      />
      {data?.origin === "whatsapp" && <>
        <InputField
          name="whatsapp_id"
          title="Channel"
          value={data?.whatsapp_id}
          mt="md"
          onChange={({whatsapp_id}) => {
            setData(d => ({...d, whatsapp_id}))
          }}
          fieldType={InputFieldTypes.SELECT}
          options={whatsapps.map(wpp => ({ label: wpp?.data?.phone, value: wpp._id }))}
        />
        <Group justify="flex-end">
          <Button onClick={() => setOpenWhatsapps(true)} size="xs" variant="light">{str("CONNECT")}</Button>
        </Group>
      </>}
      {(data?.origin === "wati") && <>
        <InputField
          name="template_name"
          title="Template Name"
          value={data?.template_name}
          mt="md"
          onChange={({template_name}) => {
            setData(d => ({...d, template_name}))
          }}
        />
        <InputField
          name="api_key"
          title="API KEY"
          value={data?.api_key}
          mt="md"
          onChange={({api_key}) => {
            setData(d => ({...d, api_key}))
          }}
        />
      </>}
      {["zapier", "wati"].includes(data?.origin) && <Group mt="md">
          <Text fw="bold" style={{flex: 1}}>{str("VARIABLES")}</Text>
          <ActionIcon
            onClick={() => {
              setData(d => ({...d, variables:
                [...(data?.variables ?? []), {}]
              }))
            }}
          ><FaPlus/></ActionIcon>
        </Group>}
      {(v.variables ?? []).map((it,ii) => <>
        <Group align="flex-end" mt="md">
          <InputField
            name="key"
            title={str("VARIABLE")}
            style={{flex: 0.4}}
            value={it?.key}
            readOnly={it?.blocked}
            onChange={({key}) => {
              setData(d => ({...d, variables:
                (data?.variables ?? []).map((it2, ij) => ii === ij ? { ...it2, key } : it2)
              }))
            }}
          />  
          <InputField
            name="description"
            title={str("AGENTS.VARIABLE_DESCRIPTION_TITLE")}
            placeholder={str("AGENTS.VARIABLE_DESCRIPTION_PLACEHOLDER")}
            style={{flex: 0.6}}
            value={it?.description}
            onChange={({description}) => {
              setData(d => ({...d, variables:
                (data?.variables ?? []).map((it2, ij) => ii === ij ? { ...it2, description } : it2)
              }))
            }}
          />
          <ActionIcon size="lg" variant="outline" onClick={() => {
            setData(na => ({ ...na, variables: (data?.variables ?? []).filter((it2, ij) => ii !== ij)}))
          }}><FaTrash /></ActionIcon>
        </Group>
      </>)}
    </>}
    {["transfer_call"].includes(data?.type) && <InputField
      name="phone_number"
      title="Transfer To"
      placeholder="+14412345678"
      mt="md"
      value={data?.phone_number}
      onChange={({phone_number}) => {
        setData(d => ({...d, phone_number}))
      }}
    />}
    {["contact_status", "whatsapp", "integration", "call_webhook", "send_sms"].includes(data?.type) && <>
    {/* "The message you will say to callee when calling this tool. Make sure it fits into the conversation smoothly." */}
      <InputField
        mt="sm"
        name="speak_during_execution"
        title={str("AGENTS.SPEAK_DURING_EXECUTION")}
        fieldType={InputFieldTypes.SWITCH}
        value={data?.speak_during_execution}
        onChange={({speak_during_execution}) => {
          setData(d => ({...d, speak_during_execution}))
        }}
      />
      <InputField
        name="execution_message_description"
        placeholder={str("AGENTS.SPEAK_DURING_EXECUTION_DESCRIPTION")}
        mt="xs"
        disabled={!data?.speak_during_execution}
        value={data?.execution_message_description}
        onChange={({execution_message_description}) => {
          setData(d => ({...d, execution_message_description}))
        }}
      />
      <InputField
        mt="sm"
        name="speak_after_execution"
        title={str("AGENTS.SPEAK_AFTER_EXECUTION")}
        fieldType={InputFieldTypes.SWITCH}
        value={data?.speak_after_execution}
        onChange={({speak_after_execution}) => {
          setData(d => ({...d, speak_after_execution}))
        }}
      />
    </>}

    <Modal
      opened={!!newCalendar}
      onClose={() => setNewCalendar(null)}
    >
      <InputField
        name="title"
        placeholder={str("TITLE")}
        value={newCalendar?.title}
        onChange={({title}) => { setNewCalendar(nc => ({ ...nc, title })) }}
      />
      <Button
        mt="md"
        onClick={() => {
          SAVE_CALENDAR({ ...newCalendar })
          .then(res => {
            setData(d => ({ ...d, calendar: res._id }));
            setNewCalendar(null);
            loadCalendars && loadCalendars();
          })
          .catch(err => {
            showNotification(err);
          })
        }}
      >{str("SAVE")}</Button>
    </Modal>
    
    <Modal
      opened={!!openWhatsapps}
      onClose={() => {
        setOpenWhatsapps(null)
        loadWhatsapps && loadWhatsapps()
      }}
    >
      {openWhatsapps && <Whatsapps />}
    </Modal>
  </>
}