import { api } from "./api"

export const TRIGGER_CALL = async (params : any) => {
    const {data} = await api.post(`/calls/trigger`, params)
    return data;
}

export const EXPORT_CALLS = async (params : any) => {
    const {data} = await api.post(`/calls/exportCsv`, params)
    return data;
}

export const GET_ALL_CALLS = async (params : any) => {
    const {data} = await api.post(`/calls/all`, params)
    return data;
}

export const GET_CALLS_DYNAMIC_FILTERS = async () => {
    const {data} = await api.get(`/calls/dynamic/filters`)
    return data;
}

export const RUN_AFTER_CALL = async (callId : string) => {
    const {data} = await api.patch(`/calls/after-call/${callId}`)
    return data;
}
